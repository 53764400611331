import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Auth from "./components/auth";
import AuthService from "./services/auth.service";
import Dashboard from "./components/dashboard";
import PrivateRoute from "./components/privateroute";

const user = AuthService.getCurrentUser();

const goToPage = (destination = "auth") => {
  let path = "/";

  path += user ? "dashboards" : destination;

  return path;
};

function App() {
  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to={goToPage()} />
        <Route path="/auth" component={Auth} />
        <PrivateRoute
          authenticated={user}
          path="/dashboards"
          component={Dashboard}
        />
      </Switch>
    </Router>
  );
}

export default App;
