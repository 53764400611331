import React, { useState, useEffect } from "react";
import Sidebar from "../sidebar";
import Topbar from "../topbar";
import Viewer from "../viewer";
import ApiService from "../../services/api.service";
import AuthService from "../../services/auth.service";

import "./styles.css";

function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol - required
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator

  const valid = !!pattern.test(str);

  console.log(valid ? "Valid URL supplied" : "EmbedID supplied", str);

  return valid;
}

let useUrl = false;

const Dashboard = (props) => {
  const user = props.user;

  // Dashboard listing side-menu - functionality for burger-menu bar on smaller screens
  const [dashboardTitle, setDashboardTitle] = useState("Choose dashboard");
  const [showMenu, setMenuDisplay] = useState(false);
  const toggleMenu = (show) => {
    setMenuDisplay(!show);
  };

  // Parameters for Dashbard to be displayed
  const [dashboards, setDashboards] = useState(ApiService.getCurrentDashboards());
  const [embedId, setEmbedId] = useState(null);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [embedToken, setEmbedToken] = useState(null);

  const setDashboard = (embedIdOrURL, dashboardTitle = "Choose Dashboard") => {
    useUrl = validURL(embedIdOrURL);
    const url = useUrl
      ? embedIdOrURL
      : "https://public.domo.com/embed/pages/" + embedIdOrURL;

    setEmbedToken(null);
    setEmbedUrl(url);
    setEmbedId(useUrl ? null : embedIdOrURL);
    setDashboardTitle(dashboardTitle);
    setMenuDisplay(false);
  };

  useEffect(() => {
    if (!dashboards){
      ApiService.getDashboards().then((response) => {
          if(response) setDashboards(response)
      });
    }
    
    if (dashboards && !embedUrl){
      setDashboard(dashboards[0].embedId, dashboards[0].shortTitle);
    }

    if (embedUrl && embedId && !embedToken){
      ApiService.getDashboardParameters(embedId).then((response) =>
        setEmbedToken(response)
      );
    }
  });

  return (
    <React.Fragment>
      <Topbar username={user && (user.name || user.username)} />

      <div className="dropdown" onClick={() => toggleMenu(showMenu)}>
        <span className="dropbtn">
          {dashboardTitle} <i className="fas fa-caret-down"></i>
        </span>
        <Sidebar
          dashboards={dashboards}
          setDashboard={setDashboard}
          className={showMenu && "visible dropdown-content"}
        />
      </div>

      <Viewer embedUrl={embedUrl} embedToken={embedToken} useSrc={useUrl} />
    </React.Fragment>
  );
};

export default Dashboard;
