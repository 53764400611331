const API_URL = process.env.REACT_APP_API_URL || "";

class AuthService {
  login() {
    return this.checkServerSession().then((response) => {
      return response.json().then((data) => {
        if (response.status === 400)
          return this.sendToLoginForm(data.destination);
        return this.setUser(JSON.stringify(data));
      });
    });
  }

  checkServerSession() {
    return fetch(`${API_URL}/auth/login`).catch((error) =>
      console.log("Could not check for user session:", error)
    );
  }

  // Redirect user to login destination
  sendToLoginForm(destination = `${API_URL}/auth/callback`) {
    return window.location.replace(destination);
  }

  setUser(userData) {
    if (!userData) return false;

    try {
      localStorage.setItem("user", userData);
      return true;
    } catch (error) {
      console.log(error);
    }

    return false;
  }

  getCurrentUser() {
    let result = false;

    try {
      let user = localStorage.getItem("user");
      result = typeof user === "string" ? JSON.parse(user) : false;
    } catch (error) {
      console.log("getCurrentUser: ", error);
    }

    if (
      result &&
      (!result.loginTime || result.loginTime < Date.now() - 600000)
    ) {
      localStorage.clear();
      result = false;
    }

    return result;
  }

  authAction() {
    const pathname = window.location.pathname;
    const action = pathname.replace("/auth", "").replaceAll("/", "");
    return action;
  }

  logout() {
    const action = this.authAction();

    if (action === "logout") {
      localStorage.removeItem("user");
      localStorage.clear();

      window.location = `${API_URL}/auth/logout/`;
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }
}

export default new AuthService();
