import React, { useEffect } from "react";
import AuthService from "../../services/auth.service";

import "./styles.css";

const home = (path = "/") => window.location.replace(path);

const login = () =>
  AuthService.login().then((response) => {
    if (response) return home();
  });

const logout = () =>
  AuthService.logout().then((response) => {
    if (response) return home();
  });

function Auth(props) {
  useEffect(() => {
    const isAuthenticated = AuthService.getCurrentUser() !== false;
    if (!isAuthenticated) return login();
    return logout();
  });

  return (
    <div className="loginPage">
      <div className="loader">
        <span>Loading</span>
      </div>
    </div>
  );
}

export default Auth;
