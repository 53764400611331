import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const Topbar = (props) => {
  const [showMenu, setMenuDisplay] = useState(false);
  const toggleMenu = (show) => {
    setMenuDisplay(!show);
  };

  return (
    <div className="topnav">
      <div className="logo">
        <img src="/logo.png" alt="logo" />
      </div>

      <div className={"topSmallScreenMenu" + (showMenu ? " visible" : "")}>
        <div className="menu">
          <a className="active" href="#home">
            Reporting Dashboard
          </a>
        </div>

        <div className="accountButtons">
          <span id="userAccount">{props.email}</span>
          <Link to="/auth/logout">Log out</Link>
        </div>
      </div>

      <div id="sidebarToggle">
        <span onClick={() => toggleMenu(showMenu)} className="toggle" />
      </div>
    </div>
  );
};

export default Topbar;
