import React from "react";

import "./styles.css";

function getGeneratedPageURL(embedToken, embedUrl) {
  if (!embedToken) { return null; }

  const source =
    "" +
    "<html>" +
    "   <body>" +
    '        <form id="form" action="' +
    encodeURI(embedUrl) +
    '" method="post">' +
    '        <input type="hidden" name="embedToken" value="' +
    encodeURI(embedToken) +
    '">' +
    "        </form>" +
    "        <script>" +
    '        document.getElementById("form").submit();' +
    "        </script>" +
    "    </body>" +
    "</html>";

  return source;
}

const Viewer = (props) => (
  <div id="main">
    <section className="cover">
      <div id="loader" className="loader">
        <span>Loading</span>
      </div>
      <div className="iframe-container">
        <iframe
          id="dashboard-viewer"
          srcDoc={getGeneratedPageURL(props.embedToken, props.embedUrl)}
          src={props.useSrc ? props.embedUrl : ''}
          title="Dashboard Viewer"
          onLoad={() =>
            setTimeout(
              document.getElementById("loader").classList.add("hide"),
              5000
            )
          }
        />
      </div>
    </section>
  </div>
);

export default Viewer;
