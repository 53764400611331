import React from "react";
import { NavLink } from "react-router-dom";
import "./styles.css";

const listDashboards = (dashboards, setDashboard) => {
  if (!Array.isArray(dashboards)) { return false };

  return dashboards.map((dashboardItem, index) => (
    <li key={index}>
      <NavLink
        to={`/dashboards/${index + 1}`}
        activeClassName="active"
        onClick={() => setDashboard(dashboardItem.embedId)}
      >
        <span className="icon solid fa-chart-area">
          {dashboardItem.shortTitle}
        </span>
      </NavLink>
    </li>
  ));
};

const sidebar = ({dashboards, className, setDashboard}) => (
  <div id="sidebar" className={className || ""}>
    <div className="top">
      <nav id="nav">
        <ul>{listDashboards(dashboards, setDashboard)}</ul>
      </nav>
    </div>
  </div>
);

export default sidebar;
