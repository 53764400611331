const API_URL = process.env.REACT_APP_API_URL || "";

class ApiService {
  getDashboards() {
    return this.fetchDashboards();
  }

  getCurrentDashboards() {
    let result = false;

    try {
      result =
        localStorage.getItem("dashboards") !== null
          ? JSON.parse(localStorage.getItem("dashboards"))
          : false;
    } catch (error) {
      console.log("getCurrentDashboards: ", error);
    }

    return result;
  }

  fetchDashboards() {
    // Fetch
    return fetch(`${API_URL}/dashboard/data`)
      .then((response) => {
        if (response.status === 400) window.location.assign("/");
        if (response.status !== 200) throw new Error(response.text());
        return response.text();
      })
      .then((response) => {
        let dashboardArray = JSON.parse(response);
        
        if (!Array.isArray(dashboardArray) || !dashboardArray.length) return false;
        
        localStorage.setItem("dashboards", response);
        
        return dashboardArray;
      })
      .catch((err) => console.log("Failed to fetch dashboards:", err));
  }

  getDashboardParameters(embedId, embedUrl = false) {
    console.log("getDashboardParameters");

    // Fetch
    return fetch(`${API_URL}/dashboard/data/${embedId}`)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.text());
        return response.json();
      })
      .then((response) => {
        return response;
      })
      .catch((err) =>
        console.log("Failed to fetch dashboard parameters:", err)
      );
  }

  postToken(token, embedUrl) {
    if (!token || !embedUrl) return false;

    const params = { embedToken: token };

    const postParams = Object.keys(params)
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
      })
      .join("&");

    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Credentials": true,
    };

    fetch(embedUrl, {
      method: "POST",
      headers: headers,
      body: postParams,
    });
  }
}

export default new ApiService();
